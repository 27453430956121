<template>
  <notification-snackbar
    v-if="currentNotification"
    :message="currentNotification.message"
    :action-text="currentNotification.actionText"
    :notification="currentNotification"
    :active="currentNotification !== null"
    :auto-dismiss="currentNotification.autoDismiss"
    @dismiss-notification="triggerCallbackAndDismiss(currentNotification.id)"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useNotificationStore } from '@/stores/notifications';
import { storeToRefs } from 'pinia';
import NotificationSnackbar from './NotificationSnackbar.vue';

export default defineComponent({
  name: 'NotificationSnackbarWrapper',
  components: { NotificationSnackbar },
  setup() {
    // Use the notification store
    const notificationStore = useNotificationStore();

    // Extract reactive properties using storeToRefs
    const { currentNotification } = storeToRefs(notificationStore);

    // Destructure actions directly from the store
    const { triggerCallbackAndDismiss } = notificationStore;

    return {
      currentNotification,
      triggerCallbackAndDismiss,
    };
  },
});
</script>

<style scoped>
</style>
