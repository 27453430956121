import { ContentRegistrationFormSchema } from '@/common/types';
import { input as grants } from '@/schema/ContentRegistrationGrants';

export const schemas: ContentRegistrationFormSchema[] = [
  {
    title: 'Crossref Grant Schema',
    schema: grants.jsonSchema,
    version: '1.0.0',
  },
];
