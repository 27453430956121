/* eslint no-console: ["error", { allow: ["error"] }] */
import {
  ContentRegistrationFormPayload,
  CDSUserRoleAndToken,
  LegacyUserRoleCredentials, isValidXmlGenerationPayload, isValidCredentials,
} from '@/common/types';
import { inject, InjectionKey, provide } from 'vue';
import { createMachine, InterpreterFrom, assign } from 'xstate';
import { useActor, useInterpret } from '@xstate/vue';
import { useInspector } from './utils';
import {
  downloadRecordFile,
  recordWithUuid,
  getDepositorInfo,
  getUserRoleCredential,
  loadRecordFromFile,
  persistRecord,
} from '@/utils/helpers';
import {
  deposit,
  DepositRequestBody,
  DepositRequestHeaders,
  DepositResponse,
} from '@/utils/deposit';
import { sendParent } from 'xstate/lib/actions';
import * as Sentry from '@sentry/browser';
import { ALERT_LEVEL_ERROR } from '@/constants';
import { useDynamicRouter } from '@/composable/useDynamicRouter';

const { routerInstance } = useDynamicRouter();
import { XMLRenderingService } from '@/services/xmlRendering/XmlRenderingService';
import { FormData } from '@/forms/types';
import { useDialogStore } from '@/stores/dialogs';
import { getAppService } from '@/statemachines/app.machine';
import {
  DepositSubmissionAuthenticationError,
  DepositSubmissionError,
  DepositSubmissionNetworkError,
  XmlConversionBadInputError,
} from '@/errors';
import { useAppMachine } from '@/composable/useAppMachine';
import { useUserStore } from '@/stores/user';
import { useRecordStore } from '@/stores/records';
import { RouteNames } from '@/router';
import { useSessionStore } from '@/stores/session';
import {useNotificationStore} from "@/stores/notifications";
import {extractErrorMessageAsync} from "@/utils/error/extractMessage";
const grantsXMLGenerator = () => import('@/services/grantsXMLGenerator');

export type FormDataContext = {
  currentRecord: ContentRegistrationFormPayload | null;
  xmlString: string;
  recordHistory: {
    [key: string]: ContentRegistrationFormPayload;
  };
  credentials: CDSUserRoleAndToken;
  depositResponse: DepositResponse;
  lastDepositResponse: DepositResponse;
  formData?: FormData;
  dialogStore: unknown;
};

export const machine =
  /** @xstate-layout N4IgpgJg5mDOIC5QDED2AnAtgEQIYBdcACAWVwGMALASwDswA6aiAGzAGIBlAFQEEAlbgH0AotgCS3cQDkA4kOQB5fiUSgADqljV81VLTUgAHogC0ANgAsAZgYAOAJyWArAAZrd18-MAmV+YAaEABPMwB2MIZLS19zBwBGJwd3MLsAXzSgtCw8QlIKGnomVg5sRQB1aQAZRV5sIX4RAGFlbENNbV19QxMEC1dLBjD4uzDrBzi-VzDAkLN4qyiYn2c7cwW7UfiMrIwcAmIyKjpGZjZ2GrqG5taFfkUSBXEqkXatHT0DJGMza2cfBgJMITdwxLz+IKhPp-BhxSxhHx-OxIzbpTIgbL7PJHQqnEpcbjKETXFr8NrfDofbrfXr9cywyz+VzMyzxFwuSFmFbxJa+SyeDyjAY7DF7XKHAonYrnTgAVQAQiRJCTWm9Op8er9zPTrPznM4-vEvGEvJyEM4eZtEut4rrLD4Ij4RZjxfljkUWKhcBB+GByBgIBdavVGqTsAw5U0miJOJw1VSvqBeisGG42YjRvz-G5nGbTKkoqkvIiHNZjf5nWKDm7cQxPd7ff70IHLiGbmSGMheM9411Ez9oa4ARaHP9RnExg4HHn4j4AXCZqtR+Z9b4MujaKgIHBDC7qzipWcwL2NTSueZIs5S2F4WW7PFgd48xneYjnDNkcN3JWcvvJUVsDASldFoKAiEbAMT2pJN5lcOxYQvCZxntd9zGsM053sYYkmSREEQNZwfyxCV3UYesfT9SCKXePtNT6RZZ08RE5x8Gx32nOY+h8NDAWWNDkiXZxLCI10DyKKBUG4VARAgHRMSg-taXWZwoiEuw3EZAYfFGM0jRUv5UgfaJ-DBJ10T3bF-2Paj1WggcLAvewnDcDwvF8CFONMDxIicWJtP8hwxnXNIgA */
  createMachine(
    {
      predictableActionArguments: true,
      context: {} as FormDataContext,
      tsTypes: {} as import('./form-data.machine.typegen').Typegen0,
      schema: {
        context: {
          currentRecord: {} as ContentRegistrationFormPayload,
        } as FormDataContext,
        events: {} as
          | { type: 'START_EDITING_NEW_RECORD'; }
          | { type: ''; payload: ContentRegistrationFormPayload }
          | { type: 'SUBMIT_RECORD', record: ContentRegistrationFormPayload }
          | { type: 'CANCEL' }
          | { type: 'FORM_CANCEL' }
          | { type: 'RESTART' }
          | { type: 'XML_GENERATED'; xmlString: string }
          | { type: 'CHECK_CREDENTIALS' }
          | { type: 'REQUEST_CREDENTIALS' }
          | { type: 'LOGIN_BOX_CLOSED' }
          | {
              type: 'CREDENTIALS_VERIFIED';
              credentials: CDSUserRoleAndToken | null;
            }
          | { type: 'DEPOSIT_RECORD' }
          | { type: 'CLEAR_ERROR_MESSAGE' }
          | { type: 'DEPOSIT_RECORD_SUCCESS'; response: DepositResponse }
          | {
              type: 'DEPOSIT_RECORD_FAILURE';
              response: DepositResponse;
              errorMessage: string;
            },
      },
      initial: 'idle',
      id: 'FormDataMachine',
      states: {
        idle: {
          on: {
            START_EDITING_NEW_RECORD: {
              target: 'editingForm',
              actions: ['clearCurrentRecord'],
            },
          },
        },
        editingForm: {
          id: 'editingForm',
          on: {
            SUBMIT_RECORD: {
              target: 'submitRecord',
              actions: 'conditionallyAssignCurrentRecord',
            },
            FORM_CANCEL: {
              target: '#FormDataMachine.idle',
            },
            RESTART: {
              actions: ['clearFormData'],
              target: '#FormDataMachine.idle',
            },
          },
        },
        submitRecord: {
          initial: 'checkCredentials',
          states: {
            generateXML: {
              invoke: {
                src: 'generateXMLString',
                onError: [
                  {
                    actions: ['setErrorMessageFromInvoke', 'notifyXmlError'],
                    target: '#FormDataMachine.editingForm',
                  },
                ],
              },
              on: {
                XML_GENERATED: {
                  actions: 'updateXMLString',
                  target: 'depositRecord',
                },
              },
            },
            checkCredentials: {
              invoke: {
                src: 'checkCredentials',
              },
              on: {
                CREDENTIALS_VERIFIED: {
                  actions: 'assignCredentialsToContext',
                  target: 'generateXML',
                },
                REQUEST_CREDENTIALS: {
                  actions: 'requestCredentials',
                },
                CANCEL: {
                  target: '#FormDataMachine.editingForm',
                },
                LOGIN_BOX_CLOSED: {
                  target: '#FormDataMachine.editingForm',
                },
              },
            },
            depositRecord: {
              invoke: {
                src: 'makeDeposit',
                onError: [
                  {
                    actions: 'setErrorMessageFromInvoke',
                    target: '#FormDataMachine.idle',
                  },
                ],
              },
              on: {
                DEPOSIT_RECORD_SUCCESS: {
                  actions: [
                    'updateDepositResponse',
                    'moveRecordToCompleted',
                  ],
                  target: 'submissionComplete',
                },
                DEPOSIT_RECORD_FAILURE: {
                  actions: [
                    'notifyDepositError',
                    'logDepositFailure',
                    'updateDepositResponse',
                    'handleDepositFailure',
                  ],
                  target: '#editingForm',
                },
              },
            },
            submissionComplete: {
              entry: ['clearFormData'],
              always: { target: '#FormDataMachine.idle' }
            },
          },
        },
      },
    },
    {
      actions: {
        clearCurrentRecord: assign((context) => {
          return {
            ...context,
            currentRecord: null,
          };
        }),
        conditionallyAssignCurrentRecord: assign((context, event) => {
          const sessionStore = useSessionStore();
          if (event.record) {
            return {
              ...context,
              currentRecord: event.record,
            };
          }
          return context;
        }),
        notifyXmlError: async (context, event) => {
          const appService = useActor(useAppMachine());
          const formService = useActor(
            appService.state.value.children.formDataMachine
          );
          const sessionStore = useSessionStore();
          const dialogStore = useDialogStore();
          if (event.data instanceof XmlConversionBadInputError) {
            dialogStore.showDialog(
              event.data,
              () => sessionStore.downloadRecord(),
              'Download JSON',
              false,
              {
                formService: formService,
              }
            );
          } else {
            dialogStore.showDialog(
              event.data as Error,
              () => formService.send('SUBMIT_RECORD'),
              'RETRY',
              true,
              {
                formService: formService,
              }
            );
          }
        },
        notifyDepositError: async (context, event) => {
          const dialogStore = useDialogStore();
          const userStore = useUserStore();
          const appService = useActor(useAppMachine());
          const formService = useActor(
            appService.state.value.children.formDataMachine
          );
          switch (event.response.statusCode) {
            case 401:
              // this means the token cannot be trusted - destroy it
              userStore.logoutUser();
              dialogStore.showDialog(
                new DepositSubmissionAuthenticationError(
                  'Authentication error'
                ),
                () => formService.send('SUBMIT_RECORD'),
                'RETRY',
                true,
                {
                  formService: formService,
                }
              );
              break;
            case -1:
              dialogStore.showDialog(
                new DepositSubmissionNetworkError(event.response.message),
                () => formService.send('SUBMIT_RECORD'),
                'RETRY',
                true,
                {
                  formService: formService,
                }
              );
              break;
            case 500:
              dialogStore.showDialog(
                new DepositSubmissionNetworkError(event.response.message, {
                  code: event.response.statusCode,
                }),
                () => formService.send('SUBMIT_RECORD'),
                'RETRY',
                true,
                {
                  formService: formService,
                }
              );
              break;
            default:
              dialogStore.showDialog(
                new DepositSubmissionError(event.response.message, {
                  code: event.response.statusCode,
                  domainMessage: event.response.body,
                })
              ),
                () => formService.send('SUBMIT_RECORD'),
                'RETRY',
                true,
                {
                  formService: formService,
                };
          }
        },
        logDepositFailure: (context, event) => {
          Sentry.captureException(
            new Error('Metadata deposit error'),
            (scope) => {
              scope.setContext('context', context);
              scope.setContext('event', event);
              return scope;
            }
          );
        },
        requestCredentials: sendParent('REQUEST_CREDENTIALS'),
        clearFormData: assign((_context, _event) => {
          return {
            formData: {},
            currentRecord: {} as ContentRegistrationFormPayload,
            xmlString: '',
          };
        }),
        setErrorMessageFromInvoke: async (context, event) => {
          const notificationStore = useNotificationStore();
          notificationStore.addNotification(await extractErrorMessageAsync(event.data) || 'An unknown error occurred');
        },
        updateDepositResponse: assign((context, event) => {

          // Update the context with the new deposit response
          return {
            ...context,
            depositResponse: event.response,
            lastDepositResponse: event.response,
          };
        }),
        moveRecordToCompleted: async (context, event) => {
          const notificationStore = useNotificationStore();
          const recordStore = useRecordStore();
          const completedId = context.currentRecord?.recordId;
          if (!completedId) {
            notificationStore.addNotification(`Deposited record ID not found. Unable to move to completed.`)
            return;
          }
          if (!context.currentRecord) {
            notificationStore.addNotification(`Deposited record not found. Unable to move to completed.`)
            return;
          }
          recordStore.moveRecordToCompleted(context.currentRecord, event.response);
        },
        updateXMLString: assign((context, event) => {
          return { xmlString: event.xmlString };
        }),
        assignCredentialsToContext: assign((context, event) => ({
          credentials: event.credentials ?? context.credentials,
        })),
      },
      services: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        checkCredentials: (context, _event) => (send) => {
          const userStore = useUserStore();
          const loginSuccessListener = (ev: Event) => {
            const customEvent = ev as CustomEvent<CDSUserRoleAndToken>;
            send({
              type: 'CREDENTIALS_VERIFIED',
              credentials: customEvent.detail,
            });
          };
          const cancelListener = (_ev: Event) => {
            send('CANCEL');
          };
          if (userStore.getUserIfValid()) {
            send({
              type: 'CREDENTIALS_VERIFIED',
              credentials: userStore.user,
            });
          } else {
            window.addEventListener('loginSuccess', loginSuccessListener);
            window.addEventListener('authCancel', cancelListener);

            send('REQUEST_CREDENTIALS');
          }
          return () => {
            window.removeEventListener(
              'authRoleAuthorised',
              loginSuccessListener
            );
            window.removeEventListener('authCancel', cancelListener);
          };
        },
        generateXMLString: (context, _event) => async (send) => {
          if (!isValidXmlGenerationPayload(context.currentRecord) || !isValidCredentials(context.credentials)) {
            throw new Error("Missing or invalid record and/or credentials for XML generation.");
            return;
          }
          const xmlString = await XMLRenderingService.generateXML(
            context.currentRecord.recordType,
            context.currentRecord.formData,
            true,
            getDepositorInfo(
              context?.credentials,
              context?.currentRecord?.recordType
            )
          );
          send({ type: 'XML_GENERATED', xmlString: xmlString });
        },
        makeDeposit: (context, event) => async (send) => {
          const userRoleCrendential = getUserRoleCredential(
            context?.credentials
          );
          try {
            const headers: DepositRequestHeaders = {
              token: context.credentials.token,
              refresh_token: context.credentials.refreshToken,
            };
            const body: DepositRequestBody = {
              usr: userRoleCrendential,
              role: context.credentials.role,
              operation: 'doMDUpload',
              mdFile: new Blob([event.xmlString as string], {
                type: 'text/xml',
              }),
            };
            const depositResponse = await deposit(body, headers);
            if (depositResponse.status === 'success') {
              send({
                type: 'DEPOSIT_RECORD_SUCCESS',
                response: depositResponse,
              });
            } else {
              const depositErrorMessage = `${depositResponse.message}`;
              send({
                type: 'DEPOSIT_RECORD_FAILURE',
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                response: depositResponse,
                errorMessage: depositErrorMessage,
              });
            }
          } catch (error) {
            console.error(error);
            send({
              type: 'DEPOSIT_RECORD_FAILURE',
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              // @ts-ignore
              response: undefined,
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              // @ts-ignore
              errorMessage: error.message,
            });
          }
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          return () => {};
        },
      },
    }
  );

export type FormDataService = InterpreterFrom<typeof machine>;
export const FormDataServiceSymbol: InjectionKey<FormDataService> =
  Symbol('form-data.service');

export function getFormDataService(options?: Record<string, unknown>) {
  if (process.env.NODE_ENV === 'test') {
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    const service = interpret(machine, {
      ...options,
      devTools: useInspector(),
    });
    service.start();
    return service;
  }
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  const service = useInterpret(machine, {
    ...options,
    devTools: useInspector(),
  });
  return service;
}

export function provideFormDataService(options?: Record<string, unknown>) {
  const service = getFormDataService(options);
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  provide(FormDataServiceSymbol, service);

  return service;
}

export function useFormDataService() {
  const service = inject(FormDataServiceSymbol);

  if (!service) {
    throw new Error('FormData service not provided.');
  }

  return useActor(service);
}
