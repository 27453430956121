export const routes = [
  {
    path: '/records',
    /* internal name: '/records' */
    /* no component */
    children: [
      {
        path: '',
        name: '/records/',
        component: () => import('/app/src/pages/records/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/records/[id]',
        component: () => import('/app/src/pages/records/[id].vue'),
        /* no children */
      },
      {
        path: ':id/complete',
        name: '/records/[id].complete',
        component: () => import('/app/src/pages/records/[id].complete.vue'),
        /* no children */
      },
      {
        path: 'new/:recordType?',
        name: '/records/new.[[recordType]]',
        component: () => import('/app/src/pages/records/new.[[recordType]].vue'),
        /* no children */
      }
    ],
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

