import {
  JournalArticleMapper,
  mapJournalArticleToItemTree,
} from '@/itemTree/mapping/JournalArticleMapper';
import {ContentRegistrationType, UserInfo, XMLGenerationStrategy} from '@/common/types';
import { FormData } from '@/forms/types';
import {env} from "@/env";
import {
  XmlConversionBadInputError,
  XmlConversionError,
  XmlConversionNetworkError,
} from '@/errors';
import {
  EMAIL_CONTENT_REGISTRATION_DEPOSITOR_UNKNOWN,
  NAME_CONTENT_REGISTRATION_DEPOSITOR_UNKNOWN
} from "@/constants/contact";
import { mapData } from '../formToConvertApiMapper';

export const insertCitationsToXML = (
  inputXML: string,
  references: string | null | undefined
): string => {
  // Guard clause: Check for empty, null, or undefined references
  if (!references || !references.trim()) {
    console.warn('No references provided. Returning original XML.');
    return inputXML;
  }
  const parser = new DOMParser();
  const serializer = new XMLSerializer();
  const NAMESPACE = 'http://www.crossref.org/schema/5.3.1';

  // Parse the input XML
  const xmlDoc = parser.parseFromString(inputXML, 'application/xml');

  // Handle parsing errors
  const parseError = xmlDoc.querySelector('parsererror');
  if (parseError) {
    throw new Error(
      `Invalid XML input: ${parseError.textContent?.trim() || 'Unknown error'}`
    );
  }

  // If no references, return input XML untouched
  const referenceLines = references
    .split('\n')
    .map((line) => line.trim())
    .filter((line) => line.length > 0);

  if (referenceLines.length === 0) {
    return inputXML;
  }

  // Find the <journal_article> tag
  const journalArticle = xmlDoc.querySelector('journal_article');
  if (!journalArticle) {
    throw new Error('<journal_article> tag not found in the provided XML.');
  }

  // Create <citation_list> container
  const citationList = xmlDoc.createElementNS(NAMESPACE, 'citation_list');

  // Iterate through references and create <citation> elements
  referenceLines.forEach((line, index) => {
    const citationElement = xmlDoc.createElementNS(NAMESPACE, 'citation');
    citationElement.setAttribute('key', `ref${index}`);

    const unstructuredCitation = xmlDoc.createElementNS(
      NAMESPACE,
      'unstructured_citation'
    );
    unstructuredCitation.textContent = line;

    citationElement.appendChild(unstructuredCitation);
    citationList.appendChild(citationElement);
  });

  // Append the <citation_list> to <journal_article>
  journalArticle.appendChild(citationList);

  // Serialize the updated XML back to a string
  return serializer.serializeToString(xmlDoc);
};

export class JournalArticleXmlRenderingStrategy
  implements XMLGenerationStrategy
{
  async generateXML(
    recordType: ContentRegistrationType,
    data: FormData,
    prettyPrint = false,
    userInfo?: UserInfo
  ): Promise<string> {
    // Request XML from Manifold
    const mappedFormData = mapData(data);
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    const raw = JSON.stringify({
      doiBatchHead: {
        depositorName:
          userInfo?.depositorName ||
          NAME_CONTENT_REGISTRATION_DEPOSITOR_UNKNOWN,
        depositorEmail:
          userInfo?.depositorEmail ||
          EMAIL_CONTENT_REGISTRATION_DEPOSITOR_UNKNOWN,
        registrant: 'content-registration-form',
      },
      ...mappedFormData,
    });
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow' as RequestRedirect,
    };

    try {
      const response = await fetch(env().convertApiEndpoint, requestOptions);
      if (!response.ok) {
        const errorResponse = await response.json();
        const errorMessage = JSON.stringify(errorResponse, null, 2);
        if (response.status === 400) {
          throw new XmlConversionBadInputError(`${response.statusText}`, {
            code: response.status,
            domainMessage: errorMessage,
          });
        }
        if (response.status === 500) {
          throw new XmlConversionError(response.statusText, {
            code: response.status,
          });
        }
        throw new Error('Unknown error');
      }
      const result = await response.text();
      const xmlWithCitations = insertCitationsToXML(
        result,
        mappedFormData?.journalArticle?.references
      );
      return xmlWithCitations;
    } catch (e) {
      // Determine if the error is a fetch-specific error
      if (e instanceof TypeError && e.message === 'Failed to fetch') {
        throw new XmlConversionNetworkError(e.message);
      } else {
        // Handle other unexpected errors
        throw e;
      }
    }
  }
}
