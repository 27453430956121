import { resolveTitle } from './../utils/routes';
import { computed, ComputedRef, ref } from 'vue';
import { useRoute } from 'vue-router';
import { usePageTitle } from './usePageTitle';

// Shared reactive state
const sharedMainSectionTitle = ref<string | null>(null);

export function useMainSectionTitle(): {
  mainSectionTitle: ComputedRef<string | null>;
} {
  const pageTitle = usePageTitle();
  const route = useRoute();

  // Computed property that updates the shared ref
  const mainSectionTitle = computed(() => {
    if (pageTitle.plainTitle.value) {
      sharedMainSectionTitle.value = pageTitle.plainTitle.value;
    } else {
      const matchedRoute = route.matched?.[0]; // Get the highest parent route that matches
      const resolvedTitle = resolveTitle(matchedRoute);
      sharedMainSectionTitle.value = resolvedTitle;
    }
    return sharedMainSectionTitle.value;
  });

  return { mainSectionTitle };
}
