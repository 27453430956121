import { ref, computed, Ref } from 'vue';
import { useHead } from '@unhead/vue';
import { useTranslationNamespace } from '@/composable/useI18n';
import { I18nNamespace } from '@/i18n';

type UsePageTitle = {
  pageTitle: Ref<string>;
  plainTitle: Ref<string>;
  setTitle: (newTitle?: string) => void;
};

// Shared reactive state
const currentTitle = ref<string | null>(null);

export function usePageTitle(): UsePageTitle {
  // Translation namespace
  const nsT = useTranslationNamespace(I18nNamespace.App);

  // Computed translations
  const defaultTitle = computed(() => nsT('welcome-to-brand'));
  const brandName = computed(() => nsT('brandname'));

  // Computed plain title and full page title
  const plainTitle = computed(() => currentTitle.value || defaultTitle.value);
  const pageTitle = computed(() => `${plainTitle.value} | ${brandName.value}`);

  // Update the page title (sets the current title and updates the head)
  const setTitle = (newTitle?: string): void => {
    currentTitle.value = newTitle || null;

    useHead({
      title: pageTitle.value,
    });
  };

  // Initialize head with default title once
  useHead({
    title: pageTitle.value,
  });

  return {
    pageTitle,
    plainTitle,
    setTitle,
  };
}
