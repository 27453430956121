import { ContentRegistrationInputSchema } from '@/common/types';
import jsonSchema from '@/schema/ContentRegistrationGrantsSchema.json';
import affiliationUISchema from '@/schema/ContentRegistrationAffiliationUISchema.json';
import awardAmountUISchema from '@/schema/ContentRegistrationAwardAmountUISchema.json';
import depositorUISchema from '@/schema/ContentRegistrationDepositorUISchema.json';
import descriptionUISchema from '@/schema/ContentRegistrationDescriptionUISchema.json';
import doiDataUISchema from '@/schema/ContentRegistrationDoiDataUISchema.json';
import fundingUISchema from '@/schema/ContentRegistrationFundingUISchema.json';
import grantUISchema from '@/schema/ContentRegistrationGrantUISchema.json';
import personUISchema from '@/schema/ContentRegistrationPersonUISchema.json';
import projectTitleUISchema from '@/schema/ContentRegistrationProjectTitleUISchema.json';
import { Generate, UISchemaTester } from '@jsonforms/core';

const matchPath =
  (path: string): UISchemaTester =>
  (_schema, schemaPath) =>
    schemaPath === path ? 10 : -1;

const uischema = Generate.uiSchema(jsonSchema);

const data = undefined;
const uischemas = [
  {
    tester: matchPath('#/properties/affiliation'),
    uischema: affiliationUISchema,
  },
  {
    tester: matchPath('#/properties/award_amount'),
    uischema: awardAmountUISchema,
  },
  {
    tester: matchPath('#/properties/depositor'),
    uischema: depositorUISchema,
  },
  {
    tester: matchPath('#/properties/description'),
    uischema: descriptionUISchema,
  },
  {
    tester: matchPath('#/properties/doi_data'),
    uischema: doiDataUISchema,
  },
  {
    tester: matchPath('#/properties/funding'),
    uischema: fundingUISchema,
  },
  {
    tester: matchPath('#/properties/grants'),
    uischema: grantUISchema,
  },
  {
    tester: matchPath('#/properties/investigators'),
    uischema: personUISchema,
  },
  {
    tester: matchPath('#/properties/project-title'),
    uischema: projectTitleUISchema,
  },
  {
    tester: matchPath('#/properties/project'),
    // we don't need any specialization, we just don't want the default group layout
    uischema: Generate.uiSchema(
      jsonSchema.properties.grants.properties.project,
      'VerticalLayout'
    ),
  },
];
export const input: ContentRegistrationInputSchema = {
  jsonSchema,
  uischema,
  data,
  uischemas,
};
