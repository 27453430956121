import { defineStore } from 'pinia';
import { Notification } from "@/common/types";

export const useNotificationStore = defineStore('alertStore', {
  state: () => ({
    notifications: [] as Notification[],
    currentNotification: null as Notification | null,
    nextId: 0
  }),
  actions: {
    addNotification(message: string, actionText?: string, onAction?: (() => void) | undefined, autoDismiss: boolean = true) {
      const id = this.nextId++;
      this.notifications.push({ id, message, actionText, onAction, autoDismiss });
      this.showNextNotification();
    },
    showNextNotification() {
      if (!this.currentNotification && this.notifications.length > 0) {
        this.currentNotification = this.notifications.shift()!;
        this.handleAutoDismiss(this.currentNotification);
      }
    },
    handleAutoDismiss(notification: Notification) {
      if (notification.autoDismiss) {
        setTimeout(() => {
          if (this.currentNotification?.id === notification.id) {
            this.autoDismissNotification(notification.id); // Auto-dismiss without invoking the callback
          }
        }, 4000); // auto-dismiss after 4 seconds
      }
    },
    triggerCallbackAndDismiss(id: number) {
      // When the user clicks the optional action trigger, the callback will be run and the notification dismissed
      if (this.currentNotification && this.currentNotification.id === id) {
        if (this.currentNotification.onAction) {
          this.currentNotification.onAction(); // Execute the callback
        }
        this.dismissNotification(id); // Dismiss after running the callback
      }
    },
    autoDismissNotification(id: number) {
      if (this.currentNotification && this.currentNotification.id === id) {
        this.dismissNotification(id); // Dismiss without executing any callback
      }
    },
    dismissNotification(id: number) {
      if (this.currentNotification && this.currentNotification.id === id) {
        this.currentNotification = null;
        this.showNextNotification(); // Proceed to the next notification if any
      }
    }
  }
});
