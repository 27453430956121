import { ContentRegistrationFormPayload } from '@/common/types';
import { FormData } from '@/forms/types';
import { sanitizeFilename } from './helpers';

/**
 * Retrieves the electronic ISSN (online) if available; otherwise, falls back to the print ISSN.
 * @param data - The source form data.
 * @returns The ISSN string for either electronic or print if available; otherwise, `null`.
 */
export const getOnlineOrPrintIssn = (data: FormData): string | null => {
  if (data?.journal?.issn?.onlineIssn) {
    return data.journal.issn.onlineIssn;
  }

  if (data?.journal?.issn?.printIssn) {
    return data.journal.issn.printIssn;
  }

  return null;
};

/**
 * Generates the filename for a journal article.
 * @param record - The journal article record.
 * @returns The generated filename.
 */
export const getFileNameForJournalArticleRecord = (
  record: ContentRegistrationFormPayload
): string => {
  let fileName: string;

  try {
    // Extract ISSN and article title
    const issn = getOnlineOrPrintIssn(record.formData);
    const articleTitle = record?.formData?.article?.title;

    // Prepare the name parts array and filter out any null/undefined values
    const nameParts = [issn, articleTitle].filter((part) => part != null);

    if (nameParts.length > 0) {
      // Join the valid parts with a hyphen, then sanitize the result while retaining Unicode
      fileName = sanitizeFilename(nameParts.join(' '));
    } else {
      throw new Error('Article title or ISSN is missing');
    }
  } catch (error) {
    // Fallback to recordId if slugification fails or required parts are missing
    fileName = record.recordId;
  }

  return `${fileName}.json`;
};
