import { AnySchema } from 'ajv';
import { createAjv } from '@pvale/vue-vuetify';
import cloneDeep from 'lodash/cloneDeep';
import { ContentRegistrationType } from '@/common/types';
import merge from "lodash/merge";

// Predefined defaults for journal articles
const defaultJournalArticleFormData = {
  journal: {
    titles: {},
    issn: {},
  },
  issue: {},
  article: {
    contributors: [{}],
    abstract: [{}],
    funding: [{}],
    publicationDates: {},
  },
};

/**
 * Dynamically applies defaults to form data based on the form type.
 * If the form type is a grant, it uses AJV to populate defaults based on the given JSON Schema (`schema` parameter).
 * If any initial data is provided (`initialData` parameter), it is used as the starting point, with defaults applied or merged as appropriate.
 * For journal articles, it returns a predefined set of defaults.
 *
 * @param {ContentRegistrationType} formType - The type of content registration form (e.g., grant, journal article).
 * @param {any} initialData - Initial form data to use as a baseline. Defaults or predefined values are applied to this.
 * @param {AnySchema} [schema] - The JSON Schema to use for populating defaults. Required if `formType` is `Grant`.
 * @returns {any} The form data with defaults applied based on form type and schema.
 */
export const getFormDataWithDefaults = (
  formType: ContentRegistrationType,
  initialData: any,
  schema?: AnySchema
) => {
  if (formType === ContentRegistrationType.Grant && schema) {
    const ajv = createAjv({ useDefaults: true });
    // Clone initialData to avoid mutating the original object
    const data = cloneDeep(initialData || {});
    // Populate initial data with defaults compiled in from the schema for grants
    ajv.compile(schema)(data);
    return data;
  } else if (formType === ContentRegistrationType.JournalArticle) {
    return merge({}, defaultJournalArticleFormData, initialData);
  } else {
    // Fallback for unsupported form types or missing data/schema
    return initialData || {};
  }
};
