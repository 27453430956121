import { NewRecord } from '@/statemachines/create-record.machine';
import Ajv from 'ajv';
import { schema as payloadSchema } from '@/schema/ContentRegistrationRecordPayloadSchema';
import {
  ContentRegistrationFormPayload,
  ContentRegistrationFormProps,
  ContentRegistrationFormSchema,
  ContentRegistrationType,
  LegacyUserCredentials,
  CDSUserRoleAndToken,
  UserInfo,
  ContentRegistrationRecord,
  isRecordType,
} from '@/common/types';
import { saveAs } from 'file-saver';
import some from 'lodash/some';
import isObject from 'lodash/isObject';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  EMAIL_GENERIC_CONTENT_REGISTRATION_GRANT,
  EMAIL_GENERIC_CONTENT_REGISTRATION_JOURNAL_ARTICLE,
  EMAIL_GENERIC_CONTENT_REGISTRATION_UKNOWN_RECORD_TYPE,
} from '@/constants/contact';
import { generateUuid } from './ids';
import def from 'ajv-i18n';
import sanitize from 'sanitize-filename';
import {
  getOnlineOrPrintIssn,
  getFileNameForJournalArticleRecord,
} from './journalArticles';
import { getFileNameForGrantRecord } from './grants';
import { getFormDataWithDefaults } from '@/forms/utils/formDataDefaults';
import { FormData } from '@/forms/types';
import { useRecordStore } from '@/stores/records';
import { getSchemaForFormType } from '@/forms/utils/schemaFactory';
import { useSessionStore } from '@/stores/session';
import { Sender } from 'xstate';
import {Ref, toRaw} from 'vue';

const DEFAULT_RECORD_NAME = 'NOT_NAMED';
const DEFAULT_RECORD_EXTENSION = 'json';

/**
 * Generates a filename for a new record based on its name and extension.
 *
 * @param record - The `NewRecord` object containing the record's name and extension.
 * @returns A string representing the filename.
 */
export const createRecordFilename = (record: NewRecord) => {
  return `${record.recordName}.${record.recordExtension}`;
};

/**
 * Reads an uploaded file as text.
 *
 * @param inputFile - The file to be read.
 * @returns A promise that resolves to the file's text content or rejects with a DOMException.
 */
export const readUploadedFileAsText = (
  inputFile: File
): Promise<string | ArrayBuffer | null> => {
  const temporaryFileReader: FileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result);
    };
    temporaryFileReader.readAsText(inputFile);
  });
};

export const recordWithUuid = (
  record: ContentRegistrationFormPayload
): ContentRegistrationFormPayload => {
  if (!record.recordId) {
    return { ...record, recordId: generateUuid() };
  }

  return record;
};

export const stampRecordWithNewUuid = (
  record: ContentRegistrationFormPayload
): ContentRegistrationFormPayload => {
  return { ...record, recordId: generateUuid() };
};

const normalizeRecordType = (recordType: string): ContentRegistrationType => {
  const loweredRecordType = recordType.toLowerCase();

  if (isRecordType(loweredRecordType)) {
    return loweredRecordType;
  } else {
    throw new Error(`Invalid record type: ${loweredRecordType}`);
  }
};

/**
 * Loads and validates a record from an uploaded file.
 *
 * @param file - The file containing the record data.
 * @returns A promise that resolves to the parsed and validated content registration form payload.
 * @throws Error if there is an issue reading the file or if the file's contents do not validate against the schema.
 */
export const loadRecordFromFile = async (file: File) => {
  const fileAsText = await readUploadedFileAsText(file);
  if (typeof fileAsText !== 'string') {
    throw new Error('There was an error reading your file, please try again.');
  }

  let record: ContentRegistrationFormPayload;
  try {
    record = JSON.parse(fileAsText);
  } catch (e) {
    throw new Error('The uploaded file contains invalid JSON.');
  }

  // Update the Record UUID
  record = stampRecordWithNewUuid(record);

  // Handle title and uppercase record types
  record.recordType = normalizeRecordType(record.recordType);

  const ajv = new Ajv();
  const validate = ajv.compile(payloadSchema);
  const valid = validate(record);
  if (!valid) {
    // Format validation errors for display
    const errorMessages = validate.errors
      ?.map((error) => {
        return `${error.message
          ?.charAt(0)
          .toLocaleUpperCase()}${error.message?.slice(1)}`;
      })
      .join('. ');

    throw new Error(
      `There was an error validating your file, please check and try again. ${errorMessages}`
    );
  }

  return record;
};

/**
 * Constructs a user role credential string from legacy user credentials.
 *
 * @param credentials - The legacy user credentials.
 * @returns A string representing the user and role credentials.
 */
export const getUserRoleCredential = (
  credentials: CDSUserRoleAndToken
): string => {
  return `${credentials.usr}/${credentials.role}`;
};

/**
 * Returns the generic email address for deposits by users logged in with role credentials.
 */
export const getGenericEmailForRecordType = (type: ContentRegistrationType) => {
  switch (type) {
    case ContentRegistrationType.Grant:
      return EMAIL_GENERIC_CONTENT_REGISTRATION_GRANT;
    case ContentRegistrationType.JournalArticle:
      return EMAIL_GENERIC_CONTENT_REGISTRATION_JOURNAL_ARTICLE;
    default:
      return EMAIL_GENERIC_CONTENT_REGISTRATION_UKNOWN_RECORD_TYPE;
  }
};

/**
 * Constructs a UserInfo object containing depositor name and email
 */
export const getDepositorInfo = (
  credentials: CDSUserRoleAndToken,
  recordType: ContentRegistrationType
): UserInfo => {
  return {
    depositorName: getUserRoleCredential(credentials),
    depositorEmail: credentials.usr.includes('@')
      ? credentials.usr
      : getGenericEmailForRecordType(recordType),
  };
};

/**
 * Sanitizes the filename but retains non-ASCII characters.
 *
 * @param input - The string to slugify.
 * @returns The sanitized version of the input string.
 */
export const sanitizeFilename = (input: string): string => {
  // Allow Unicode characters and just replace unsafe filesystem characters
  return sanitize(input, {
    replacement: '-', // Replace unsafe characters with a hyphen
  });
};

export const getFileNameForRecord = (
  record: ContentRegistrationFormPayload
): string => {
  switch (record.recordType) {
    case ContentRegistrationType.Grant:
      return getFileNameForGrantRecord(record);
    case ContentRegistrationType.JournalArticle:
      return getFileNameForJournalArticleRecord(record);
    default:
      return `${record.recordId}.json`;
  }
};

/**
 * Initiates the download of a content registration form record as a JSON file.
 *
 * @param record - The content registration form payload to download.
 */
export const downloadRecordFile = (
  record: ContentRegistrationFormPayload
): void => {
  const blob = new Blob([JSON.stringify(record)], {
    type: 'text/plain;charset=utf-8',
  });
  saveAs(blob, getFileNameForRecord(record));
};

/**
 * Checks if a given value is a non-empty string.
 *
 * @param value - The value to check.
 * @returns `true` if the value is a non-empty string, otherwise `false`.
 */
export const isNotEmptyString = (value: any): value is string => {
  return typeof value === 'string' && value.trim().length > 0;
};

// Utility for extracting data with safety
export function extractWithDefault<T>(
  path: string,
  object: any,
  defaultValue: T
): T {
  return get(object, path, defaultValue);
}

// Try to extract a value at `path` from `object`, or return `null` if not found or the path is undefined
export function getOrNull<T>(object, path: string) {
  return extractWithDefault(path, object, null);
}

export const hasTruthyOrNonEmpty = (values: Array<any>): boolean => {
  return values.some((value) => (Array.isArray(value) ? value.length > 0 : !!value));
};

export const hasAtLeastOnePropertyWithValue = (obj: Record<string, any>): boolean => {
  // Get all the keys of the object
  const keys = Object.keys(obj);

  // Check if there's at least one key and its value is not null/undefined/empty
  return keys.some(key => obj[key] != null && obj[key] !== '');
}

export const hasPropertyWithValue = (obj: any, propertyPath: string): boolean => {
  // Use _.get to safely access the property
  const value = get(obj, propertyPath);

  // Check if the value is not null/undefined/empty
  return value != null && value !== '';
};

export const nestedHasValue = (obj) => {
  // Check if the value is an array or object, and it's not empty
  if ((Array.isArray(obj) && isEmpty(obj)) || (isObject(obj) && isEmpty(obj))) {
    return false;
  }

  // Recursively check each property or element
  return some(obj, (value) => {
    if (Array.isArray(value) || isObject(value)) {
      return nestedHasValue(value); // Recursion for nested objects or arrays
    }
    return !!value; // Check for truthy values
  });
};

/**
 * Recursively checks if an object or array contains any primitive values.
 * A primitive value is considered to be a non-object and non-array value that is not undefined or null.
 *
 * @param obj - The object or array to check for primitive values.
 * @returns true if any primitive value is found, false otherwise.
 */
export const containsPrimitiveValue = (obj: any): boolean => {
  if (Array.isArray(obj)) {
    // Iterate over array and check each element
    return obj.some(element => containsPrimitiveValue(element));
  } else if (isObject(obj)) {
    // Iterate over object properties and check each value
    return Object.values(obj).some(value => containsPrimitiveValue(value));
  } else {
    // Check if the value is a primitive (not undefined or null and not an empty string)
    return obj !== undefined && obj !== null && obj !== '';
  }
};

/**
 * Transforms the ORCID ID input to always include https://orcid.org/
 *
 * @param input - The ORCID ID input string.
 * @returns The transformed ORCID ID string.
 */
export const transformOrcidInput = (input: string): string => {
  const cleanedInput = input.trim();
  const orcidPattern = /^\d{4}-\d{4}-\d{4}-\d{3}[0-9X]$/;

  if (orcidPattern.test(cleanedInput)) {
    return `https://orcid.org/${cleanedInput}`;
  }
  return cleanedInput;
};

/**
 * Transforms the DOI input to strip the domain and return just the DOI.
 *
 * @param input - The DOI input string.
 * @returns The transformed DOI string.
 */
export const formatDoiInput = (input: string): string => {
  if (typeof input !== 'string') {
    return input;
  }
  const cleanedInput = input.trim();
  const doiUrlPattern = /^https?:\/\/(dx\.)?doi\.org\/(10\.\d{4,9}\/[-._;()\/a-zA-Z0-9]+)$/i;

  const match = doiUrlPattern.exec(cleanedInput);
  if (match) {
    return match[2];
  }

  return cleanedInput;
};

export const autoFormatISSN = (input: string): string => {
  const cleanedInput = input.trim();
  console.log('auto format issn', input);
  // Check if the input is exactly 8 digits
  if (/^\d{8}$/.test(cleanedInput)) {
    return `${cleanedInput.slice(0, 4)}-${cleanedInput.slice(4)}`;
  } else {
    return input; // Return the original input if it's not a valid ISSN
  }
};

const getRecordNameOrDefault = (record: ContentRegistrationRecord) => {
  return record.recordName || DEFAULT_RECORD_NAME;
};

export const createRecordWithId = (
  id: string,
  type: ContentRegistrationType,
  data: FormData | null = null
): ContentRegistrationFormPayload => {
  const schema = getSchemaForFormType(type);
  const dataWithDefaults = getFormDataWithDefaults(type, data, schema.schema);
  return {
    recordId: id,
    recordType: type,
    formData: dataWithDefaults,
    schemaName: schema.title,
    schemaVersion: schema.version,
    recordExtension: DEFAULT_RECORD_EXTENSION,
  };
};

export const mergeRecordDefaults = (
  input: ContentRegistrationRecord,
  schema: ContentRegistrationFormSchema
): ContentRegistrationFormPayload => {
  const recordNameOrDefault = getRecordNameOrDefault(input);
  return {
    recordId: input.recordId,
    recordName: recordNameOrDefault,
    recordType: input.recordType,
    formData: getFormDataWithDefaults(
      input.recordType,
      input.formData,
      schema.schema
    ),
    fileName: createRecordFilename({
      recordExtension: DEFAULT_RECORD_EXTENSION,
      recordName: recordNameOrDefault,
      recordType: input.recordType,
    }),
    schemaName: schema.title,
    schemaVersion: schema.version,
    recordExtension: DEFAULT_RECORD_EXTENSION,
  };
};

export const persistRecord = (record) => {
  const store = useRecordStore();
  const recordWithId = recordWithUuid(record);
  store.saveDraft(recordWithId);
};

/**
 * Updates form data in the session store.
 *
 * @param data - The new form data to update.
 */
export const updateFormData = (
  data: FormData
) => {
  const sessionStore = useSessionStore();
  sessionStore.updateFormData(data);
};

/**
 * Initiates editing by updating session storage and dispatching to the form data machine.
 *
 * @param record - The target record.
 * @param formDataService - A reactive ref from useActor. The 'state' and 'send' are typed as 'any'
 *                          due to misalignment with Vue's reactivity system and the current
 *                          state machine exports. This is a temporary workaround until the refactor
 *                          to XState 5, which is expected to resolve these type alignment issues
 *                          for both state management and event dispatching.
 */
export const startRecordEdit = (
  record: ContentRegistrationFormPayload,
  formDataService: { state: Ref<any>; send: Sender<any> }
) => {
  const sessionStore = useSessionStore();
  sessionStore.setAndPersistCurrentRecord(record);
  formDataService.send({
    type: 'START_EDITING_NEW_RECORD'
  });
};

export const getRecordFromDraftsOrSession = (id: string) => {
  const recordStore = useRecordStore();
  const sessionStore = useSessionStore();
  const recordInDrafts = recordStore.drafts.get(id)
  const recordInSession = sessionStore.currentRecord;
  if (!recordInDrafts && recordInSession?.recordId === id) {
    console.debug(`No draft record, but matching session for record ${id}`);
    return recordInSession;
  }
  if (recordInDrafts) {
    console.debug(`Draft record for record ${id}`);
    if (recordInDrafts.recordId !== id){
      console.error(`Record missmatch for id ${id} : ${recordInDrafts.recordId}`);
      return null;
    }
    return recordInDrafts
  }
  return null;
}

export const getOrCreateRecord = (recordId: string, schema: ContentRegistrationFormSchema, type: ContentRegistrationType, formData?: FormData): ContentRegistrationFormPayload => {
  const recordFromStore = getRecordFromDraftsOrSession(recordId);
  if (recordFromStore) {
    console.debug(`Found record ${recordId} in store`);
    return mergeRecordDefaults(recordFromStore, schema)
  }
  console.debug(`No draft record or matching session,  creating new record for ${recordId}`);
  const newRecord = createRecordWithId(recordId, type, formData || {})
  return mergeRecordDefaults(newRecord, schema)
}
