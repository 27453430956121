import { ResponseError } from '@/generated/openapi';

/**
 * Safely extracts an error message from synchronous error types (strings, Error instances, and objects with a message property).
 * @param {unknown} e - The error to extract the message from.
 * @param {string} [defaultMessage='Unknown error'] - The default message to return if an error message can't be extracted.
 * @returns {string} The extracted error message or the provided defaultMessage.
 */
export function extractErrorMessageSync(
  e: unknown,
  defaultMessage: string | null = 'Unknown error'
): string | null {
  if (typeof e === 'string') {
    return e;
  }

  if (e instanceof Error) {
    return e.message;
  }

  if (typeof e === 'object' && e !== null && 'message' in e) {
    const { message } = e as { message: unknown };
    if (typeof message === 'string') {
      return message;
    }
  }

  return defaultMessage;
}

/**
 * Safely extracts an error message from various types of errors (strings, Error instances, ResponseError instances, and objects with a message property).
 * This function can handle both synchronous and asynchronous errors.
 * @param {unknown} e - The error to extract the message from.
 * @param {boolean} returnDefaultMessage - Whether to return the default error message when extraction fails.
 * @returns {Promise<string | null>} The extracted error message, or null if extraction fails and returnDefaultMessage is false.
 */
export async function extractErrorMessageAsync(
  e: unknown,
  returnDefaultMessage = true
): Promise<string | null> {
  // First, try to extract the message synchronously with a null defaultMessage
  const syncMessage = extractErrorMessageSync(e, null);
  if (syncMessage !== null) {
    return Promise.resolve(syncMessage);
  }

  // If the error is a ResponseError, handle it asynchronously
  if (e instanceof ResponseError) {
    let message: string | null = returnDefaultMessage ? 'Unknown error' : null;

    try {
      const json = await e.response.json();
      message = json.message || message;
    } catch (error) {
      // Handle JSON parsing error, if needed
      console.error(error);
    }

    return message;
  }

  return Promise.resolve(returnDefaultMessage ? 'Unknown error' : null);
}
