import { v4 as uuidv4 } from 'uuid';

const hasWindow = typeof window !== 'undefined';

export const generateUuid = (): string => {
  if (hasWindow && window.isSecureContext) {
    return crypto.randomUUID(); // Only works in secure contexts (HTTPS or localhost)
  } else {
    return uuidv4(); // Fallback to uuid library's v4 function
  }
};
