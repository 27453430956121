import {
  ContentRegistrationFormPayload,
  ContentRegistrationRecord, DepositedMetadataRecord,
} from '@/common/types';
import { generateUuid } from '@/utils/ids';
import { UUID } from 'crypto';
import { defineStore } from 'pinia';
import {DepositResponse} from "@/utils/deposit";
import {useNotificationStore} from "@/stores/notifications";
import {downloadRecordFile} from "@/utils/helpers";
import {useTranslation} from "@/composable/useI18n";
import {useSessionStore} from "@/stores/session";

const DRAFTS_LIMIT = 10;
const COMPLETED_LIMIT = 20;

const t = useTranslation();

/**
 * The long-term recordStore permits loading records by ID even if the session has ended and restarted,
 * allowing users to resume work seamlessly after closing or refreshing the browser.
 *
 */
export const useRecordStore = defineStore('records', {
  state: () => ({
    drafts: new Map<string, ContentRegistrationFormPayload>(),
    completed: new Map<string, DepositedMetadataRecord>(),
  }),
  getters: {
    getCompletedRecordById: (state) => (uuid: string) => {
      console.log(`Get record by UUID ${uuid}`);
      return state.completed.get(uuid);
    },
  },
  actions: {
    saveDraft(record: ContentRegistrationFormPayload) {
      console.debug(`Saving draft for record ${record.recordId}`)
      if (!record.recordId) {
        console.error(record);
        throw new Error('Record ID not set in record.');
      }
      const recordId = record.recordId;
      this.drafts.set(recordId, record);

      this.enforceLimit(this.drafts, DRAFTS_LIMIT)
    },
    moveRecordToCompleted(record: ContentRegistrationFormPayload, response: DepositResponse) {
      const notificationStore = useNotificationStore();
      const id = record.recordId;
      if (!id) {
        notificationStore.addNotification(t('records-record-not-found'));
        return;
      }
      const depositedRecord: DepositedMetadataRecord = {
        ...record,
        depositedAt: new Date(),
        depositResponse: response,
      }
      this.completed.set(id, depositedRecord);
      this.drafts.delete(id);

      this.enforceLimit(this.completed, COMPLETED_LIMIT);
    },
    downloadRecord(uuid: string) {
      const notificationStore = useNotificationStore();
      const record = this.getCompletedRecordById(uuid);
      if (!record){
        notificationStore.addNotification(t('records-record-not-found-to-download'))
        return;
      }
      const { depositedAt, depositResponse, ...recordToDownload } = record;

      downloadRecordFile(recordToDownload);
    },
    enforceLimit(map: Map<string, unknown>, limit: number) {
      while (map.size > limit) {
        const oldestKey = map.keys().next().value; // Get the first key
        map.delete(oldestKey); // Remove the oldest entry
        console.debug(`Removed oldest record with ID ${oldestKey} to enforce limit of ${limit}`);
      }
    },
  },
  persist: {
    storage: localStorage,
    serializer: {
      serialize(state) {
        // Convert the Map to a storable array format
        const draftsArray = Array.from(state.drafts.entries());
        const completedArray = Array.from(state.completed.entries());
        // Use JSON.stringify to convert the array to a string
        return JSON.stringify({
          ...state,
          drafts: draftsArray,
          completed: completedArray,
        });
      },
      deserialize(str) {
        const data = JSON.parse(str);
        // Reconstruct the Map from the array of entries
        data.drafts = new Map(data.drafts);
        data.completed = new Map(data.completed);
        return data;
      },
    },
  },
});
