<template>
  <div>
    <!-- error messages are likely to be longer, hence multiline is applied -->
    <!-- visibility is controlled by the parent component, so timemout="-1" -->
    <v-snackbar
      :multi-line="hasActionText"
      location="bottom"
      :timeout="-1"
      :model-value="active"
      class="notification-snackbar"
    >
      {{ message }}
      <template #actions="">
        <v-btn
          v-if="actionText"
          color=""
          variant="text"
          @click="dismissCurrentNotification"
        >
          {{ actionText }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
/**
 * The Notification Snackbar
 * @displayName Notification Snackbar
 *
 * Displays a Snackbar with a notification message.
 * If the notification has the level 'error', the style of the snackbar is altered.
 */
import { AlertLevel } from '@/common/types';
import { ALERT_LEVEL_INFO } from '@/constants';
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'NotificationSnackbar',
  props: {
    /**
     * The notification message to show
     */
    message: { type: String, required: true },
    /**
     * The severity of the notification
     */
    level: { type: String as () => AlertLevel, default: null },
    /**
     * Whether the snackbar should show
     */
    active: Boolean,
    /**
     * Whether non-error messages should be auto-dismissed
     */
    autoDismiss: Boolean,
    /**
     * The optional action button text
     */
    actionText: String,
  },
  setup(props, context) {
    const dismissHandler = () => {
      context.emit('dismiss-notification');
    };
    /**
     * Dismiss the current notification
     */
    const dismissCurrentNotification = () => {
      if (props.active) {
        dismissHandler();
      }
    };
    /**
     * Determine whether the notification is an error message
     */
    const hasActionText = computed(() => !!props.actionText);
    return {
      hasActionText,
      dismissHandler,
      dismissCurrentNotification,
    };
  },
});
</script>
