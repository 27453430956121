// @ts-nocheck

import { inject, InjectionKey, provide } from 'vue';
import {
  createMachine,
  InterpreterFrom,
  assign,
  spawn,  
  interpret,
} from 'xstate';
import { useActor, useInterpret } from '@xstate/vue';
import { useInspector } from './utils';
import {
  CredentialsService,
  machine as credentialsMachine,
} from '@/statemachines/credentials.machine';
import {
  FormDataService,
  machine as formDataMachine,
} from '@/statemachines/form-data.machine';
import { SearchService } from '@/statemachines/search.machine';
import { send } from 'xstate/lib/actions';
import { SupportedLocales } from '@/i18n';
import { Alert } from '@/common/types';
import { useAppMachine } from '@/composable/useAppMachine';
import { store } from '@/store';

type AppContext = {
  locale: SupportedLocales;
  msg?: string;
  credentialsMachineRef: CredentialsService;
  searchMachineRef: SearchService;
  formDataMachineRef: FormDataService;
};

export const machine =
  /** @xstate-layout N4IgpgJg5mDOIC5QEEA2YBOAXABAWQEMBjACwEsA7MAOjInQGJkAZAUQCUAVRUABwHtYZLGX4UeIAB6IALAA5qABgBMcgIzLFM+QHY1ANgCshgDQgAnog3VlATnWG1OxQGZnOwy+UBfb2bSYuISklDTEIgBuYAwAwmzI7BICQiJiEtIIALTKmtQuiga2+vmeLmpqphaI2TIu1IaucnL6Gi5FhrY6vn4gFPwQcBIB2PjE5FS09GBJgsKi4khS1Xb61Nr68l76cjItMmaWWTo6edrOqs3K+r7+6CPB42FEkdOLyXNpixmOay616gVlDJbC59AdqhpDPVlH9yvplIYdOs1DcQMMgmNQjMUvN0tUvIo1rtNlcdntwVkyqsGi45LYtLZjAUjN1vEA */
  createMachine(
    {
      predictableActionArguments: true,
      tsTypes: {} as import('./app.machine.typegen').Typegen0,
      schema: {
        context: {
          locale: 'en',
        } as AppContext,
        events: {} as
          | { type: 'ALERT'; msg: string; alert: Alert }
          | { type: 'CLEAR' }
          | { type: 'REQUEST_CREDENTIALS' }
          | { type: 'UPDATE_LOCALE'; locale: SupportedLocales }
          | { type: 'LOGIN_BOX_CLOSED' },
      },
      initial: 'idle',
      id: 'App Machine',
      entry: assign({
        credentialsMachineRef: () =>
          spawn(credentialsMachine, {
            name: 'credentialsMachine',
            sync: false,
          }),
        // searchMachineRef: () =>
        //   spawn(searchMachine, { name: 'searchMachine', sync: false }),
        formDataMachineRef: () =>
          spawn(formDataMachine, { name: 'formDataMachine', sync: false }),
      }),
      on: {},
      states: {
        idle: {
          on: {
            REQUEST_CREDENTIALS: {
              actions: 'requestCredentials',
            },
            UPDATE_LOCALE: {
              actions: 'updateLocale',
            },
            LOGIN_BOX_CLOSED: {
              actions: 'reportLoginBoxClosed',
            },
          },
        },
        active: {
          on: {
          },
        },
      },
    },
    {
      actions: {
        updateLocale: assign((context, event) => {
          return { locale: event.locale };
        }),
        requestCredentials: send('SHOW_LOGIN_BOX', {
          to: (context) => context.credentialsMachineRef,
        }),
        reportLoginBoxClosed: send('LOGIN_BOX_CLOSED', {
          to: (context) => context.formDataMachineRef,
        }),
      },
    }
  );

export type AppService = InterpreterFrom<typeof machine>;
export const AppServiceSymbol: InjectionKey<AppService | undefined> =
  Symbol('app.service');

let serviceInstance: AppService | null = null;

export function getAppService() {
  return useAppMachine();
}

export function provideAppService() {
  const service = getAppService();
  provide(AppServiceSymbol, service);

  return service;
}

export function useAppService() {
  const service = store.appService;

  if (!service) {
    throw new Error('App service not provided.');
  }

  return useActor(service);
}
