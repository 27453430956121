import { ContentRegistrationFormPayload } from '@/common/types';
import {downloadRecordFile, loadRecordFromFile} from '@/utils/helpers';
import { defineStore } from 'pinia';
import { useRecordStore } from './records';
import {DepositResponse} from "@/utils/deposit";
import { useNotificationStore } from './notifications';

/**
 * Session Store
 *
 * This store is used to manage in-progress, session-lived data for the current user session, such as in-progress form data.
 * It provides a short-term, ephemeral state that is lost once the session ends. The purpose of this store is to
 * maintain a working copy of data that is actively being modified by the user. Data stored here will not persist beyond
 * the current session, making it ideally suited for handling draft records or form inputs.
 *
 * The store also ensures that changes to in-progress form data are consistently persisted to long-term storage via the recordStore,
 * maintaining synchronization between session state and persisted state.
 */

// Helper function to persist the current record to the record store for long-term storage.
const persistCurrentRecord = (record: ContentRegistrationFormPayload) => {
  const recordStore = useRecordStore();
  recordStore.saveDraft(record);
};

export const useSessionStore = defineStore('session', {
  state: () => ({
    currentRecord: null as ContentRegistrationFormPayload | null,
  }),
  actions: {
    // Set the current record in the session store and persist it to the record store.
    setAndPersistCurrentRecord(record: ContentRegistrationFormPayload) {
      this.currentRecord = record;
      persistCurrentRecord(record);
    },
    setCurrentRecord(record: ContentRegistrationFormPayload) {
      this.currentRecord = record;
    },
    // Clear the current in-progress record from the session store.
    clearCurrentRecord() {
      this.currentRecord = null;
    },
    // Update the form data of the current record, then persist the updated record.
    updateFormData(formData: any) {
      if (this.currentRecord) {
        this.setAndPersistCurrentRecord({
          ...this.currentRecord,
          formData,
        });
      } else {
        console.error('No current record found to update form data.');
      }
    },

    // Download the current record as a file.
    downloadRecord() {
      if (!this.currentRecord) {
        const notificationStore = useNotificationStore();
        notificationStore.addNotification('Could not find record to download.');
        return;
      }
      downloadRecordFile(this.currentRecord);
    },

    // Load a new record from a file
    async loadRecordFromFile(file) {
      try {
        const record = await loadRecordFromFile(file);
        this.setCurrentRecord(record);
        return record;
      } catch (error) {
        console.error("Error loading record:", error);
        throw error; // re-throw to allow component-level handling
      }
    },
  },
  persist: {
    storage: sessionStorage,
  },
});
