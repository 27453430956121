import { store } from '@/store';
import { useNotificationStore } from "@/stores/notifications";



export const useAlert = () => {
  const alertError = (message: string) => {
    sendAlert(message, 'error');
  };

  const alertSuccess = (message: string) => {
    sendAlert(message, 'success');
  };

  const alertWarning = (message: string) => {
    sendAlert(message, 'warning');
  };

  const alertInfo = (message: string) => {
    sendAlert(message, 'info');
  };

  const sendAlert = (message: string, level: string) => {
    const notificationStore = useNotificationStore();

    /**
     * Material Design doesn't differentiate between notification levels shown in a snackbar, so we drop the level.
     */
    notificationStore.addNotification(message)
  };


  return {
    alertError,
    alertWarning,
    alertInfo,
    alertSuccess,
    sendAlert
  };
};
