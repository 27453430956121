<template>
  <v-footer
    :style="{
      flex: '0 1 auto',
    }"
  >
    <v-card width="100%" density="compact">
      <v-card-text>
        <div class="text-center">
          {{ $t('site_license_preamble')
          }}<a
            class="text-decoration-none text-primary"
            :href="URL_CC_BY_4"
            target="_blank"
            rel="noopener noreferrer"
            ><v-icon>mdi-creative-commons</v-icon>
            {{ $t('site_license_name') }}</a
          >
          <span class="float-left">
            <AppLink class="text-decoration-none text-primary" :to="URL_MAIN_WEBSITE">{{ $t('main_website_link_text') }}</AppLink>
          </span>
          <span class="float-right">
            <AppLink :to="URL_PRIVACY_POLICY"
            class="text-decoration-none text-primary">{{ $t('privacy_policy_title') }}</AppLink>
          </span>
        </div>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script lang="ts">
import useTheme from '@/composable/useTheme';
import {
  URL_CC_BY_4,
  URL_MAIN_WEBSITE,
  URL_PRIVACY_POLICY,
} from '@/constants/urls';
import { defineComponent } from 'vue';
import AppLink from "@/components/AppLink.vue";

export default defineComponent({
  name: 'AppFooter',
  components: {
    AppLink
  },
  setup() {
    const { currentTheme } = useTheme();
    return {
      currentTheme,
      // This is deliberately not reactive, as it won't change dynamically
      URL_PRIVACY_POLICY,
      URL_MAIN_WEBSITE,
      URL_CC_BY_4,
    };
  },
});
</script>

<style scoped>

</style>
