// Types that are applicable across components

import {
  JsonFormsUISchemaRegistryEntry,
  JsonSchema,
  UISchemaElement,
} from '@jsonforms/core';
import {FormData} from "@/forms/types";
import {Dialog} from "@/stores/dialogs";
import { AppError } from '@/errors';
import {DepositResponse} from "@/utils/deposit";

export interface Response {
  redirect: null | string;
  authenticated: boolean;
  success: boolean;
  roles: [];
  authorised: boolean;
  errorMessage: null | string;
  rolesAssigned: boolean;
  statusCode: null | number;
  username: string;
  password: string;
  role: null | string;
  token: null | string;
  firstName: null | string;
}

type UUID = string;

export const ContentRegistrationType = {
  Grant: 'grant',
  JournalArticle: 'journal-article',
} as const;

export type ContentRegistrationType =
  (typeof ContentRegistrationType)[keyof typeof ContentRegistrationType];
export const isRecordType = (obj: unknown): obj is ContentRegistrationType =>
  obj === ContentRegistrationType.Grant ||
  obj === ContentRegistrationType.JournalArticle;

export type CDSUserToken = {
  token: string;
  refreshToken: string;
};

export type LegacyUserCredentials = {
  usr: string;
  pwd: string;
  role?: string;
};

export type CDSUserRole = {
  usr: string;
  role: string;
};

export type LegacyUserRoleCredentials = LegacyUserCredentials & {
  role: string;
};

export type CDSUserRoleAndToken = CDSUserRole & CDSUserToken;

export type LegacyRoleAuthorisedEvent = {
  authenticated: boolean;
  authorised: boolean;
  errorMessage: string;
  password: string;
  redirect: string | null;
  role: string;
  roles: string[];
  rolesAssigned: boolean;
  statusCode: number;
  success: boolean;
  token: string;
  refreshToken: string;
  username: string;
};

export type AuthRequestBody = {
  username: string;
  usr: string;
  password: string;
  pass: string;
  isLoginFormValid: boolean;
  role?: string;
};

export interface MenuItem {
  label: string;
  icon: string;
  url?: string;
  routeName?: string;
}

export interface MultiLevelMenuItem {
  label: string;
  items: MenuItem[];
}

export interface SubUISchemaEntry {
  path: string;
  uischema: UISchemaElement;
}

export type ContentRegistrationInputSchema = {
  jsonSchema: JsonSchema;
  uischema: UISchemaElement | undefined;
  data: any;
  uischemas: JsonFormsUISchemaRegistryEntry[];
};

export type ContentRegistrationFormSchema = {
  title: string;
  schema: JsonSchema;
  version: string;
};

export type ContentRegistrationRecord = {
  formData?: any;
  fileName?: string;
  recordType: ContentRegistrationType;
  recordName?: string;
  recordId: string;
  recordExtension: 'json';
  schemaName?: string;
  schemaVersion?: string;
};

export type ContentRegistrationFormPayload = Required<Omit<ContentRegistrationRecord, 'fileName' | 'recordName'>> & {
  fileName?: string;
  recordName?: string;
};

export type DepositedMetadataRecord = ContentRegistrationFormPayload & {
  depositedAt: Date
  depositResponse: DepositResponse
}

export function isValidXmlGenerationPayload(record: any): record is ContentRegistrationFormPayload {
  return record !== null &&
    typeof record === 'object' &&
    record.formData !== undefined &&
    isRecordType(record.recordType);
}

export function isValidCredentials(credentials: any): credentials is CDSUserRoleAndToken {
  return credentials !== null &&
    typeof credentials === 'object' &&
    typeof credentials.token === 'string' &&
    typeof credentials.refreshToken === 'string' &&
    typeof credentials.role === 'string';
}

export type FileEventTarget = EventTarget & { files: FileList };

export type XMLGenerator = {
  (data: unknown, template: string | null): XMLDocument | string;
};

export type RecordSubmission = {
  xml: string;
};

export type Result =
  | { success: true; value: unknown }
  | { success: false; error: Error };

export type AlertLevel = 'error' | 'warning' | 'info' | 'success';

export type Alert = {
  msg: string;
  level: AlertLevel;
  timeout?: number;
};

export type AlertWithId = Alert & {
  timeout: number;
  id: number;
};
export interface Notification {
  id: number;
  message: string;
  actionText?: string;
  onAction?: () => void;
  autoDismiss: boolean;
}

export type ContentRegistrationFormData = Record<string, any>;

export type ContentRegistrationFormProps = {
  recordId: UUID;
  recordName: string;
  recordType: ContentRegistrationType;
  formData: ContentRegistrationFormData;
};

export interface UserInfo {
  depositorName: string;
  depositorEmail: string;
}

export interface XMLGenerationStrategy {
  generateXML(
    recordType: ContentRegistrationType,
    data: FormData,
    prettyPrint?: boolean,
    userInfo?: UserInfo
  ): Promise<string>;
}

export type ErrorDialogProps = {
  dialog: Dialog;
};

export type ErrorDialogEmits = {
  (event: 'dismissDialog', data?: any): void;
  (event: 'executeCallback', data?: any): void;
};

export function isAppError(error: Error): error is AppError {
  return error instanceof AppError;
}

export const PostDepositAction = {
  SameJournal: 'sameJournal',
  SameIssue: 'sameIssue',
  NewJournal: 'newJournal',
} as const;

export type PostDepositActionType =
  (typeof PostDepositAction)[keyof typeof PostDepositAction];
