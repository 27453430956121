<template>
  <!-- App.vue -->

  <v-app>
    <nav-drawer v-model="drawer"/>
    <GlobalDialog />
    <ErrorDialog />
    <notification-snackbar-wrapper />
    <header-bar ref="headerRef" class="border-b-lg border-primary border-opacity-50"></header-bar>
    <transition name="fade">
      <div>
        <LoadingSpinner
          :get-loading-color="getLoadingColor"
          :loading-state="loadingState.isLoading.value"
        />
      </div>
    </transition>
    <v-main>
      <toolbar
        :drawer="drawer"
        :title="$t('record-registration-cta')"
        @update:drawer="drawer = !drawer"
      />
      <!-- Provides the application the proper gutter -->
      <v-container>
        <template v-if="route.matched.length && route.meta.error">
          <PageNotFound
            v-if="route.matched.length && route.meta.errorCode == 404"
          />
          <NotAuthorised v-else-if="route.matched.length && route.meta.error" />
        </template>
        <template v-else-if="route.matched.length">
          <Breadcrumbs class="pl-0"></Breadcrumbs>
          <router-view></router-view>
        </template>
        <!-- If using vue-router -->
        <the-login-dialog />
      </v-container>
      <usersnap v-if="isUsersnap" />
    </v-main>
    <portal-target name="footer-prepend-content"></portal-target>
    <app-footer />
  </v-app>
</template>

<script lang="ts">
import {computed, defineComponent, provide, ref, watch} from 'vue';
import AppFooter from '@/components/AppFooter.vue';
import HeaderBar from '@/components/TheHeaderBar.vue';
import EnvironmentBanner from '@/components/EnvironmentBanner.vue'
import NavDrawer from '@/components/TheNavDrawer.vue';
import Toolbar from '@/components/TheToolBar.vue';
import TheLoginDialog from '@/components/TheLoginDialog.vue';
import { useActor } from '@xstate/vue';
import NotificationSnackbarWrapper from './modules/NotificationsModule/NotificationSnackbarWrapper.vue';
import { AppServiceSymbol, getAppService } from '@/statemachines/app.machine';
import { getSearchService, searchSymbol } from '@/statemachines/search.machine';
import Usersnap from '@/components/Usersnap.vue';
import NotAuthorised from './views/NotAuthorised.vue';
import { env } from '@/env';
import { useRoute } from 'vue-router';
import PageNotFound from './views/PageNotFound.vue';
import { store } from '@/store';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { URL_LOGO_HORIZONTAL_RECTANGLE } from './constants/urls';
import { useLoadingColors } from '@/composable/useLoadingColors';
import { useLoadingWithPromises } from './composable/useLoadingWithPromises';
import GlobalDialog from '@/components/GlobalDialog.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import useTheme, { ThemeName } from '@/composable/useTheme';
import { useTheme as useThemeVuetify } from 'vuetify';
import '@/styles/main.scss'
import ErrorDialog from "@/components/ErrorDialog.vue";

export default defineComponent({
  name: 'App',
  components: {
    ErrorDialog,
    LoadingSpinner,
    AppFooter,
    Toolbar,
    NavDrawer,
    HeaderBar,
    TheLoginDialog,
    NotificationSnackbarWrapper,
    Usersnap,
    NotAuthorised,
    PageNotFound,
    Breadcrumbs,
    GlobalDialog,
    EnvironmentBanner
  },
  setup() {
    const loadingState = useLoadingWithPromises();
    const loading = useLoadingColors();
    const getLoadingColor = computed(() => {
      return loading.color.value;
    });
    const route = useRoute();
    const searchService = getSearchService();
    provide(searchSymbol, searchService);
    provide(AppServiceSymbol, store.appService);
    store.appService = getAppService();
    const headerRef = ref(null);
    provide('headerRef', headerRef);

    /**
     * useTheme of Vuetify can only be used within a component which is why we setup the theme handling here
     */
    const { currentTheme, themes } = useTheme();
    const vuetifyTheme = useThemeVuetify();
    watch(currentTheme, (newTheme: ThemeName) => {
      if (themes.value[newTheme]) {
        vuetifyTheme.global.name.value = newTheme;
      }
    });

    return {
      route,
      store,
      URL_LOGO_HORIZONTAL_RECTANGLE,
      getLoadingColor,
      loadingState,
      currentTheme,
      headerRef,
    };
  },
  data: () => ({
    drawer: false,
  }),
  computed: {
    isUsersnap: () => env().isUsersnap,
  },
  watch: {
    $route(to, from) {
      console.debug('$route changed', to, from);
    },
  },
});
</script>

<style>
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
