<script setup lang="ts">
import { useTranslationNamespace } from '@/composable/useI18n';
import { I18nNamespace } from '@/i18n';
import { FormDataService } from '@/statemachines/form-data.machine';
import { useSessionStore } from '@/stores/session';

const t = useTranslationNamespace(I18nNamespace.ContentRegistration);
const sessionStore = useSessionStore();

</script>

<template>
  <v-btn
    v-if="sessionStore"
    icon
    variant="text"
    color="primary"
    :title="t('action-download-record')"
    :aria-label="t('action-download-record')"
    @click="sessionStore.downloadRecord"
  >
  <v-icon icon="mdi-cloud-download-outline" size="x-large"></v-icon></v-btn>
</template>
