import { schemas } from '@/schema';
import grantSchema from '@/schema/ContentRegistrationGrantsSchema.json'; // Assuming these are JSON Schema files
import journalArticleSchema from '@/forms/schemas/json/journalArticleForm/journalArticleForm.schema.json';
import {
  ContentRegistrationFormSchema,
  ContentRegistrationType,
} from '@/common/types';

/**
 * Returns the JSON Schema for a specified form type.
 *
 * @param {ContentRegistrationType} formType - The form type to retrieve the jsonSchema for.
 * @returns {JsonSchema} The corresponding JSON Schema.
 * @throws {Error} If no jsonSchema is found for the specified form type.
 */
export function getSchemaForFormType(
  formType: ContentRegistrationType
): ContentRegistrationFormSchema {
  switch (formType) {
    case ContentRegistrationType.Grant:
      return schemas[0];
    case ContentRegistrationType.JournalArticle:
      return {
        title: 'Crossref Journal Article Schema',
        version: '1.0.0',
        schema: journalArticleSchema,
      };
    default:
      throw new Error(`No schema found for form type: ${formType}`);
  }
}
