<template>
  <v-toolbar density="comfortable" dark color="primary">
    <v-app-bar-nav-icon @click="sendUpdateDrawer"></v-app-bar-nav-icon>
    <v-toolbar-title class="text-uppercase">{{
      mainSectionTitle
    }}</v-toolbar-title>
    <v-spacer></v-spacer>
  </v-toolbar>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useMainSectionTitle } from '@/composable/useMainSectionTitle';
import { usePageTitle } from '@/composable/usePageTitle';

export default defineComponent({
  name: 'Toolbar',
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    const { mainSectionTitle } = useMainSectionTitle();
    const { pageTitle, plainTitle } = usePageTitle();

    return { mainSectionTitle, pageTitle, plainTitle };
  },
  methods: {
    sendUpdateDrawer() {
      this.$emit('update:drawer');
    },
  },
});
</script>
