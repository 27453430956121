import sanitize from 'sanitize-filename';
import { ContentRegistrationFormPayload } from '@/common/types';

/**
 * Generates the filename for a grant record.
 * @param record - The grant record.
 * @returns The generated filename.
 */
export const getFileNameForGrantRecord = (
  record: ContentRegistrationFormPayload
): string => {
  let fileName: string;

  try {
    // Extract award number and project title
    const awardNumber = record?.formData?.grants?.['award-number'];
    const funderName =
      record?.formData?.grants?.project?.funding?.['funder-name'];

    // Prepare the name parts array and filter out any null/undefined values
    const nameParts = [funderName, awardNumber].filter((part) => part != null);

    if (nameParts.length > 0) {
      // Join the valid parts with a hyphen, then sanitize the result while retaining Unicode
      fileName = sanitize(nameParts.join(' '), {
        replacement: '-',
      });
    } else {
      throw new Error('Award number or project title is missing');
    }
  } catch (error) {
    // Fallback to recordId if required parts are missing
    fileName = record.recordId;
  }

  return `${fileName}.json`;
};
